import React, { useState } from "react";
import { useEffect, useRef } from "react";
//
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink";
import Web3 from "web3";
import { ethers } from "ethers";
import nftABI from "./nftabi.json";
//
import lockedStakeContractABI from "./lockedStakeContract.json";
import lockedStakeTokenABI from "./lockedStakeTokenABI.json";
// cost 0.1 = 100000000000000;
export const BlockchainContext = React.createContext("");
var web3 = null;
var account = null;
var nftContract = null;
var nftADDRESS = "0x5a499639208849c4D85386e3745F94E738a296e2"; // staking nft
var provider = null;

let lockedStakeToken = null;
let lockedStakeContract = null;
let lockedStakeTokenAddress = "0xe698f6a8E1CbC6dF52FBE894F54091baA7BfbB38";
let lockedStakeContractAddress = "0x9505137135B70b5961e4c692bc3C94b132a3246f";

export const BlockchainProvider = ({ children }) => {
  const [currentAccount, setCurrentAccount] = useState(false);
  // WC
  const [ApproveStakeState, setApproveStakeState] = useState("...");
  const [UserGlobalState, setUserGlobalState] = useState("...");

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        rpc: {
          1: "https://mainnet.infura.io/v3/2bb8830b4a274fda97f4743863b897bb",
        },
        network: "mainnet", // optional
        cacheProvider: true,
      },
    },
  };
  const web3Modal = new Web3Modal({
    network: "mainnet", // optional
    cacheProvider: true,
    providerOptions, // required
  });

  const Web3No = require("web3");

  const web3No = new Web3No(
    new Web3No.providers.HttpProvider(
      "https://mainnet.infura.io/v3/2bb8830b4a274fda97f4743863b897bb"
    )
  );
  const [mintsNo, setMintsNo] = useState(0);
  const [costNo, setCostNo] = useState(0);
  const getWeb3NO = async () => {
    let nftContractNO = new web3No.eth.Contract(nftABI, nftADDRESS);
    let _totalSupply = await nftContractNO.methods.totalSupply().call();
    let _maxSupply = await nftContractNO.methods.maxSupply().call();
    let cost = await nftContractNO.methods.cost().call();
    setCostNo(cost / 1000000000000000000);

    setMintsNo(_totalSupply);
    console.log(_totalSupply);
    console.log(_maxSupply);
  };

  /*const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        rpc: {
          1: "https://mainnet.infura.io/v3/",
        },
        network: "mainnet", // optional
        chainId: 1,
        infuraId: "2bb8830b4a274fda97f4743863b897bb",
        cacheProvider: true,
      },
    },
  };
  const web3Modal = new Web3Modal({
    network: "mainnet", // optional
    cacheProvider: true,
    providerOptions, // required
  });*/

  async function connectWallet() {
    const provider = await web3Modal.connect();
    let web3 = new Web3(provider);
    var accounts = await web3.eth.getAccounts();

    account = accounts[0];

    nftContract = new web3.eth.Contract(nftABI, nftADDRESS);

    lockedStakeContract = new web3.eth.Contract(
      lockedStakeContractABI,
      lockedStakeContractAddress
    );

    lockedStakeToken = new web3.eth.Contract(
      lockedStakeTokenABI,
      lockedStakeTokenAddress
    );

    setCurrentAccount(accounts[0]);
  }

  // END of connect wallet function

  const checkifWalletIsConnected = async () => {
    try {
      const accounts = await provider.send("eth_accounts");
      if (accounts.length) {
        setCurrentAccount(accounts[0]);
      } else {
        console.log("No accounts found");
      }
    } catch (error) {
      console.log("error");
    }
  };
  // Minting Functions Read

  const depositRef = useRef();
  let depositFinal = null;

  function onDeposit(e) {
    e.preventDefault();
    console.log(depositRef.current.value);
  }

  const withdrawRef = useRef();

  function onWithDraw(e) {
    e.preventDefault();
  }

  // locked staking functions
  const approveLockedStake = async () => {
    setApproveStakeState("Approving");
    await lockedStakeToken.methods
      .approve(lockedStakeContractAddress, "1000000000000000000")
      .send({
        to: lockedStakeToken,
        from: account,
      })
      .once("error", (err) => {
        console.log(err);
        setApproveStakeState("User Cancelled");
        setTimeout(setApproveStakeState, 1000, "");

        initPool1();
      })
      .then((receipt) => {
        console.log(receipt);
        setApproveStakeState(`Congratulations! you can now stake`);
        setTimeout(setApproveStakeState, 1000, "");
      });
  };

  async function depositLockedTokens() {
    await lockedStakeContract.methods
      .deposit(depositRef.current.value * 1000000000)
      .send({
        to: lockedStakeContract,
        from: account,
      })
      .then((receipt) => {
        console.log(receipt);
        setTimeout(initPool1(), 5000);
      });
  }
  async function claimPending() {
    await lockedStakeContract.methods.deposit(0).send({
      to: lockedStakeContract,
      from: account,
    });
  }

  async function withdrawLockedTokens() {
    await lockedStakeContract.methods
      .withdraw(/*withdrawRef.current.value / 1000000000*/)
      .send({
        // confirmed 9000
        to: lockedStakeContract,
        from: account,
      })
      .then((receipt) => {
        console.log(receipt);
        setTimeout(initPool1(), 5000);
      });
  }
  async function withdrawLockedRewards() {
    await lockedStakeContract.methods
      .emergencyRewardWithdraw(withdrawRef.current.value / 1000000000)
      .send({
        // confirmed 9000
        to: lockedStakeContract,
        from: account,
      })
      .then((receipt) => {
        console.log(receipt);
        setTimeout(initPool1(), 5000);
      });
  }
  async function emergencyWithdraw() {
    lockedStakeContract.methods
      .emergencyWithdraw()
      .send({
        // confirmed 9000
        to: lockedStakeContract,
        from: account,
      })
      .then((receipt) => {
        console.log(receipt);
        setTimeout(initPool1(), 5000);
      });
  }

  const getLockedAPR = async () => {
    const _LockedAPR = await lockedStakeContract.methods.apy().call();
    return _LockedAPR;
  };

  const getTotalLockedStake = async () => {
    const _TotalLockedStake = await lockedStakeContract.methods
      .totalStaked()
      .call();
    const _TotalLockedStakeTidy = await (
      _TotalLockedStake / 1000000000
    ).toFixed(2);

    return _TotalLockedStakeTidy;
  };
  const getContractTokenBalance = async () => {
    const _ContractTokenBalance = await lockedStakeToken.methods
      .balanceOf("0x9505137135B70b5961e4c692bc3C94b132a3246f")
      .call();
    return (_ContractTokenBalance / 1000000000).toFixed(2);
  };
  const getEarlyExitTax = async () => {
    let _EarlyExitTax = await lockedStakeContract.methods
      .exitPenaltyPerc()
      .call();
    return _EarlyExitTax;
  };

  const getUserLockedTokens = async () => {
    let _UserLockedTokens = await lockedStakeContract.methods
      .userInfo(account)
      .call();
    return (_UserLockedTokens[0] / 1000000000).toFixed(2);
  };

  const getPendingRewards = async () => {
    let _PendingRewards = await lockedStakeContract.methods
      .pendingReward(account)
      .call();
    return (_PendingRewards / 1000000000).toFixed(3);
  };

  const getUnlockData = async () => {
    let holderUnlockTime = await lockedStakeContract.methods
      .holderUnlockTime(account)
      .call();
    let UnlockTime = new Date(holderUnlockTime * 1000);
    let UnlockDay = UnlockTime.toGMTString();
    return UnlockDay;
  };
  const getRemainingDays = async () => {
    let apr = await getLockedAPR();
    let totalLockedStakeCal = await getTotalLockedStake();
    let rewards365days = (apr / 100) * totalLockedStakeCal;
    let rewardsPerDay = rewards365days / 364;
    let contractTokens = await getContractTokenBalance();
    let contractRewardsBalance = (contractTokens - totalLockedStakeCal).toFixed(
      2
    );
    let RemainingDaysCal = (contractRewardsBalance / rewardsPerDay).toFixed(2);
    return RemainingDaysCal;
  };
  const [mintAmount, setMintAmount] = useState(1);
  const incrementMintAmount = () => {
    setMintAmount(mintAmount + 1);
  };
  const decrementMintAmount = () => {
    setMintAmount(mintAmount - 1);
  };

  async function mintNFT() {
    let cost = await nftContract.methods.cost().call();
    console.log(cost + typeof cost);
    setCurrentAccount("Minting your NFT");
    // let totalCostWei = String("250000000000000000");
    let totalCostWei = String(cost);
    //  let totalCostWei = String("40000000000000000");

    nftContract.methods
      .mint(mintAmount)
      .send({
        value: totalCostWei * mintAmount,
        to: nftContract,
        from: account,
      })
      .once("error", (err) => {
        setCurrentAccount("Transaction Failed Try Again");
        initPool1();
      })
      .then((receipt) => {
        console.log(receipt);
        setCurrentAccount(`you can now view your NFT `);
        initPool1();
      });
  }
  const getTotalSupply = async () => {
    let _totalSupply = await nftContract.methods.totalSupply().call();
    return _totalSupply;
  };
  const getMaxSupply = async () => {
    let _maxSupply = await nftContract.methods.maxSupply().call();
    return _maxSupply;
  };

  const [MaxSupply, setMaxSupply] = useState(0);
  const [TotalSupply, setTotalSupply] = useState(0);

  // vars, state etc for pool1
  const [RemainingDays, setRemainingDays] = useState(0);
  const [UnlockDate, setUnlockDate] = useState(0);
  const [PendingRewards, setPendingRewards] = useState(0);
  const [UserLockedTokens, setUserLockedTokens] = useState(0);
  const [ContractTokenBalance, setContractTokenBalance] = useState(0);
  const [TotalLockedStake, setTotalLockedStake] = useState(0);
  const [LockedAPR, setLockedAPR] = useState(0);
  const [EarlyExitTax, setEarlyExitTax] = useState(0);
  const initPool1 = async () => {
    getWeb3NO();
    if (currentAccount) {
      await connectWallet();
      setUnlockDate(await getUnlockData());
      setPendingRewards(await getPendingRewards());
      setLockedAPR(await getLockedAPR());
      setContractTokenBalance(await getContractTokenBalance());
      setTotalLockedStake(await getTotalLockedStake());
      setEarlyExitTax(await getEarlyExitTax());
      setUserLockedTokens(await getUserLockedTokens());
      setRemainingDays(await getRemainingDays());
      setMaxSupply(await getMaxSupply());
      setTotalSupply(await getTotalSupply());
    }

    console.log("init1 called");
  };

  useEffect(() => {
    const hiddenInit = async () => {
      checkifWalletIsConnected();
      initPool1();
    };

    hiddenInit();
  }, []);

  return (
    <BlockchainContext.Provider
      value={{
        costNo,
        mintsNo,
        MaxSupply,
        TotalSupply,
        mintNFT,
        checkifWalletIsConnected,
        mintAmount,
        incrementMintAmount,
        decrementMintAmount,

        // State helpers
        RemainingDays,
        UserGlobalState,
        // end states
        //inits
        initPool1,
        // pool 1 Vars
        UnlockDate,
        PendingRewards,
        UserLockedTokens,
        ContractTokenBalance,
        TotalLockedStake,
        LockedAPR,
        EarlyExitTax,
        // pool 1 new vars to replace divs

        ApproveStakeState,
        getUnlockData,
        getPendingRewards,
        getContractTokenBalance,
        getTotalLockedStake,
        getLockedAPR,
        getEarlyExitTax,
        getUserLockedTokens,
        //pool 1 functions
        claimPending,
        onDeposit,
        depositRef,
        depositLockedTokens,
        approveLockedStake,
        withdrawLockedTokens,

        // p3 end
        account,
        withdrawLockedRewards,
        emergencyWithdraw,
        withdrawLockedTokens,
        onWithDraw,
        withdrawRef,
        useRef,
        connectWallet,
        currentAccount,
      }}
    >
      {children}
    </BlockchainContext.Provider>
  );
};
