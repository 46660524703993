import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./styles.css";
import "./styleCard.css";
import "./styles.scss";

import { BrowserRouter } from "react-router-dom";
import { BlockchainProvider } from "./context/BlockchainContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BlockchainProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </BlockchainProvider>
  </React.StrictMode>
);
