import Navbar from "./Navbar";
import Home from "./pages/Home";
import Footer from "./Footer";
import { Route, Routes } from "react-router-dom";
import Header from "./Header";
import GlobalState from "./pages/Component/globalstate";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </>
  );
}

export default App;
