import { BlockchainContext } from "../context/BlockchainContext";
import { useContext, useState, useRef, useEffect } from "react";
import image1 from "../../src/Images/1.png";
import image2 from "../../src/Images/3.png";
import image3 from "../../src/Images/4.png";
import image4 from "../../src/Images/5.png";
import image5 from "../../src/Images/7.png";
import image6 from "../../src/Images/13.png";
import image7 from "../../src/Images/14.png";
import image8 from "../../src/Images/William Turner 27.png";

export default function Home() {
  const {
    mintsNo,
    MaxSupply,
    TotalSupply,
    connectWallet,
    currentAccount,
    mintAmount,
    incrementMintAmount,
    decrementMintAmount,
    mintNFT,
    getMaxSupply,
    getMintCost,
    getTotalSupply,
    getNFTBalance,
    costNo,
  } = useContext(BlockchainContext);
  /* useEffect(() => {
    const init = async () => {
      checkBalance();
    };

    init();
  }, []); */
  return (
    <div className="null">
      <div className="MainContainer">
        <div className="leftSide">
          <div className="headerLeft" style={{ marginBottom: "20px" }}>
            DEAR ART,
          </div>
          <div className="headerLeft">We love you.</div>
          <div className="mintBox">
            <div className="mintBoxButton" onClick={connectWallet}>
              {" "}
              {!currentAccount
                ? "Connect"
                : `${currentAccount.slice(0, 5)}...${currentAccount.slice(
                    currentAccount.length - 4
                  )}`}{" "}
            </div>{" "}
            <div className="mintBoxInner">
              <div className="mintBoxCounter">
                <div className="mintBoxCounter" onClick={decrementMintAmount}>
                  -
                </div>{" "}
                <div className="mintBoxCounter">{mintAmount}</div>
                <div className="mintBoxCounter" onClick={incrementMintAmount}>
                  +
                </div>{" "}
              </div>{" "}
              <div className="mintBoxButton" onClick={mintNFT}>
                Mint
              </div>
              <div className="mintBoxText">{costNo} Eth per NFT</div>
            </div>
            <div className="mintBoxText">
              Limited mint live: {mintsNo} / 4253
            </div>
          </div>
          <div className="textSection1">
            {" "}
            <div className="textUnderMintBox">
              {" "}
              All mint proceeds will be invested in marketing to continue
              raising awareness and scandal on the mission.{" "}
            </div>
          </div>{" "}
          <div className="textSection2">
            <div className="textUnderLeft">Sincerely,</div>
            <div
              className="textUnderLeft"
              style={{ marginTop: "0px", fontWeight: "1000" }}
            >
              ENVIRONMENTALIST DEGENERATES{" "}
            </div>
          </div>{" "}
          <div
            className="textSection2"
            style={{ marginTop: "200px", fontWeight: "1000" }}
          >
            <div
              className="textUnderLeft"
              style={{ marginTop: "0px", fontWeight: "1000" }}
            >
              P.s.{" "}
            </div>
            <div
              className="textUnderLeft"
              style={{ marginTop: "0px", fontWeight: "normal" }}
            >
              Among others,
            </div>
          </div>{" "}
          <div className="textSection2">
            <div className="textUnderCenterSmall">
              Art = Quantifiable and unquantifiable value <br></br> Cake or
              Paint = Dirt<br></br> Climate Change = Cake or Paint on Art = Dirt
              on Quantifiable and <br></br> unquantifiable value
            </div>{" "}
          </div>
          <div
            className="textSection2"
            style={{ marginTop: "50px", fontWeight: "1000" }}
          >
            <div
              className="textUnderLeft"
              style={{ marginTop: "0px", fontWeight: "1000" }}
            >
              P.p.s.{" "}
            </div>
          </div>{" "}
          <div className="textSection2">
            <div className="textUnderCenterSmall">
              Art = Strongest and loudest form of communication in human history{" "}
              <br></br>
              Art = Most powerful operating system of all time.
              <br></br>
              => Environmentalist Degenerates is a movement, social experiment,
              <br></br>
              scandal, Art3.0, or whatever else you want it to be.
            </div>{" "}
          </div>
          <div className="textSection2" style={{ marginTop: "50px" }}>
            <div className="textUnderCenterLeft">
              This is not a political movement.
              <br></br>
              Be against the actual damaging of Art pieces.
            </div>{" "}
          </div>
          <div className="textSection2">
            <div className="textUnderCenterLeft">
              No to vandalism.
              <br></br>
              Yes to powerful, historical and meaningful scandals. Yes to Art.{" "}
            </div>{" "}
          </div>
        </div>
        <div className="rightSide">
          <div className="rightSideBorder">
            <div className="imageBorder">
              <img src={image1} className="imageDiv" />
            </div>
            <div className="imageBorder">
              <img src={image4} className="imageDiv2" />
            </div>
            <div className="imageBorder" style={{ paddingInline: "10px" }}>
              <img src={image2} className="imageDiv" />
            </div>
            <div className="imageBorder">
              <img src={image6} className="imageDiv2" />
            </div>
            <div className="imageBorder">
              <img src={image3} className="imageDiv" />{" "}
            </div>

            <div className="imageBorder">
              <img src={image7} className="imageDiv2" />
            </div>
            <div
              className="imageBorder"
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <img src={image8} className="imageDiv" />
            </div>
            <div className="imageBorder">
              <img src={image5} className="imageDiv2" />
            </div>
          </div>{" "}
        </div>{" "}
      </div>{" "}
      <div className="quoteContainer" style={{ paddingBottom: "50px" }}>
        <div
          className="quoteHeader"
          style={{
            fontFamily: "Arial",

            marginTop: "0px",
            fontWeight: "1000",
            color: "#872123",
            fontSize: "1.8rem",
          }}
        >
          WITH THE KIND PARTICIPATION OF{" "}
        </div>
      </div>{" "}
      <div className="quoteContainer" style={{ paddingTop: "50px" }}>
        {" "}
        <div className="quoteContainer">
          <div className="quoteText">
            "Climate Activists Throw Mashed Potatoes on Monet Painting"
          </div>{" "}
        </div>{" "}
        <div
          className="quoteContainer"
          style={{ marginTop: "0px", fontWeight: "1000" }}
        >
          <div
            className="quoteHeader"
            style={{ marginTop: "0px", fontWeight: "1000" }}
          >
            New York Times{" "}
          </div>
        </div>{" "}
      </div>
      <div className="quoteContainer" style={{ paddingTop: "50px" }}>
        {" "}
        <div className="quoteContainer">
          <div className="quoteText">
            "Climate activists attacking art 'severely underestimate' fragility
            of works, gallery directors warn."
          </div>{" "}
        </div>{" "}
        <div className="quoteContainer">
          <div
            className="quoteHeader"
            style={{ marginTop: "0px", fontWeight: "1000" }}
          >
            The Guardian{" "}
          </div>
        </div>{" "}
      </div>
      <div className="quoteContainer" style={{ paddingTop: "50px" }}>
        {" "}
        <div className="quoteContainer">
          <div className="quoteText">
            "How many van Goghs is one Earth worth?"
          </div>{" "}
        </div>{" "}
        <div className="quoteContainer">
          <div
            className="quoteHeader"
            style={{ marginTop: "0px", fontWeight: "1000" }}
          >
            Vox{" "}
          </div>
        </div>{" "}
      </div>
      <div className="quoteContainer" style={{ paddingTop: "50px" }}>
        <div className="quoteContainer">
          {" "}
          <div className="quoteContainer">
            <div className="quoteText">
              "Mashed Potatoes Meet Monet. Climate activists have been
              celebrated for defacing great paintings."
            </div>{" "}
          </div>{" "}
          <div
            className="quoteHeader"
            style={{ marginTop: "0px", fontWeight: "1000" }}
          >
            Jerry Saltz - Art Critic{" "}
          </div>
        </div>{" "}
      </div>
      <div className="quoteContainer" style={{ paddingTop: "50px" }}>
        {" "}
        <div className="quoteContainer">
          <div className="quoteText">
            "Climate activists pour paint on Charles Ray sculpture in Paris."{" "}
          </div>{" "}
        </div>{" "}
        <div className="quoteContainer">
          <div
            className="quoteHeader"
            style={{ marginTop: "0px", fontWeight: "1000" }}
          >
            Le Monde
          </div>
        </div>{" "}
      </div>
      <div className="quoteContainer" style={{ paddingTop: "50px" }}>
        {" "}
        <div className="quoteContainer">
          <div className="quoteText">
            "Why Climate Protesters Are Throwing Food at Art"{" "}
          </div>{" "}
        </div>{" "}
        <div className="quoteContainer">
          <div
            className="quoteHeader"
            style={{ marginTop: "0px", fontWeight: "1000" }}
          >
            TIME{" "}
          </div>
        </div>{" "}
      </div>
      <div className="quoteContainer" style={{ paddingTop: "50px" }}>
        {" "}
        <div className="quoteContainer">
          <div className="quoteText">
            "Climate activists throw black oily liquid at Klimt painting in
            Vienna"{" "}
          </div>{" "}
        </div>{" "}
        <div className="quoteContainer">
          <div
            className="quoteHeader"
            style={{ marginTop: "0px", fontWeight: "1000" }}
          >
            CNBC{" "}
          </div>
        </div>{" "}
      </div>
      <div className="quoteContainer" style={{ paddingTop: "50px" }}>
        {" "}
        <div className="quoteContainer">
          <div className="quoteText">
            "There Has to Be a Better Way to Argue About the Climate-Activist
            Attacks on Art. The strategy of 'pushing cultural buttons' comes
            with a price."{" "}
          </div>{" "}
        </div>{" "}
        <div className="quoteContainer">
          <div
            className="quoteHeader"
            style={{ marginTop: "0px", fontWeight: "1000" }}
          >
            Artnet News{" "}
          </div>
        </div>{" "}
      </div>
      <div className="quoteContainer" style={{ paddingTop: "50px" }}>
        {" "}
        <div className="quoteContainer">
          <div className="quoteText">
            "The Climate Art Vandals Are Embarrassing"{" "}
          </div>{" "}
        </div>{" "}
        <div className="quoteContainer">
          <div
            className="quoteHeader"
            style={{ marginTop: "0px", fontWeight: "1000" }}
          >
            The National News{" "}
          </div>
        </div>{" "}
      </div>
      <div className="quoteContainer" style={{ paddingTop: "50px" }}>
        {" "}
        <div className="quoteContainer">
          <div className="quoteText">
            "The Climate Art Vandals Are Embarrassing"{" "}
          </div>{" "}
        </div>{" "}
        <div className="quoteContainer">
          <div
            className="quoteHeader"
            style={{ marginTop: "0px", fontWeight: "1000" }}
          >
            The Atlantic{" "}
          </div>
        </div>{" "}
      </div>
      <div className="quoteContainer" style={{ paddingTop: "50px" }}>
        {" "}
        <div className="quoteContainer">
          <div className="quoteText">
            "Op-Ed: Is smearing food on the 'Mona Lisa' a productive form of
            climate change protest?"{" "}
          </div>{" "}
        </div>{" "}
        <div className="quoteContainer">
          <div
            className="quoteHeader"
            style={{ marginTop: "0px", fontWeight: "1000" }}
          >
            Los Angeles Times{" "}
          </div>
        </div>{" "}
      </div>
      <div className="quoteContainer" style={{ paddingTop: "50px" }}>
        {" "}
        <div className="quoteContainer">
          <div className="quoteText">
            "Climate Activists' 'Attacks on Art' Are 'Misdirected,' U.S. Museum
            Group Says"{" "}
          </div>{" "}
        </div>{" "}
        <div className="quoteContainer">
          <div
            className="quoteHeader"
            style={{ marginTop: "0px", fontWeight: "1000" }}
          >
            ArtNews Est.1920{" "}
          </div>
        </div>{" "}
      </div>
      <div className="quoteContainer" style={{ paddingTop: "50px" }}>
        {" "}
        <div className="quoteContainer">
          <div className="quoteText">
            "Why Are Climate Activists Throwing Food at Million-Dollar
            Paintings?"{" "}
          </div>{" "}
        </div>{" "}
        <div className="quoteContainer">
          <div
            className="quoteHeader"
            style={{ marginTop: "0px", fontWeight: "1000" }}
          >
            Smithsonian{" "}
          </div>
        </div>{" "}
      </div>
      <div className="quoteContainer" style={{ paddingTop: "50px" }}>
        {" "}
        <div className="quoteContainer">
          <div className="quoteText">
            "When Soup and Mashed Potatoes Are Thrown, Can the Earth Win?"{" "}
          </div>{" "}
        </div>{" "}
        <div className="quoteContainer">
          <div
            className="quoteHeader"
            style={{ marginTop: "0px", fontWeight: "1000" }}
          >
            New York Times{" "}
          </div>
        </div>{" "}
      </div>
      <div className="quoteContainer" style={{ paddingTop: "50px" }}>
        {" "}
        <div className="quoteContainer">
          <div className="quoteText">
            "Is attacking art for Climate action vandalism? Experts explain.”{" "}
          </div>{" "}
        </div>{" "}
        <div className="quoteContainer">
          <div
            className="quoteHeader"
            style={{ marginTop: "0px", fontWeight: "1000" }}
          >
            AA environment{" "}
          </div>
        </div>{" "}
      </div>
      <div className="quoteContainer" style={{ paddingTop: "50px" }}>
        {" "}
        <div className="quoteContainer">
          <div className="quoteText">
            "Climate activists in Vienna pour 'oil' over Gustav Klimt painting"{" "}
          </div>{" "}
        </div>{" "}
        <div className="quoteContainer">
          <div
            className="quoteHeader"
            style={{ marginTop: "0px", fontWeight: "1000" }}
          >
            The Art Newspaper{" "}
          </div>
        </div>{" "}
      </div>
      <div className="quoteContainer" style={{ paddingTop: "50px" }}>
        {" "}
        <div className="quoteContainer">
          <div className="quoteText">
            "The activist who threw soup on a van Gogh says it's the planet
            that's being destroyed."{" "}
          </div>{" "}
        </div>{" "}
        <div className="quoteContainer">
          <div
            className="quoteHeader"
            style={{ marginTop: "0px", fontWeight: "1000" }}
          >
            NPR{" "}
          </div>
        </div>{" "}
      </div>
      <div className="quoteContainer" style={{ paddingTop: "50px" }}>
        {" "}
        <div className="quoteContainer">
          <div className="quoteText">
            "Defacing art to stop climate change"{" "}
          </div>{" "}
        </div>{" "}
        <div className="quoteContainer">
          <div
            className="quoteHeader"
            style={{ marginTop: "0px", fontWeight: "1000" }}
          >
            The Hofstra Chronicle{" "}
          </div>
        </div>{" "}
      </div>
      <div className="quoteContainer" style={{ paddingTop: "50px" }}>
        {" "}
        <div className="quoteContainer">
          <div className="quoteText">
            "Climate Activists Throw Black 'Oil' At Gustav Klimt's 'Death And
            Life"{" "}
          </div>{" "}
        </div>{" "}
        <div className="quoteContainer">
          <div
            className="quoteHeader"
            style={{ marginTop: "0px", fontWeight: "1000" }}
          >
            Forbes{" "}
          </div>
        </div>{" "}
      </div>
      <div className="quoteContainer" style={{ paddingTop: "50px" }}>
        {" "}
        <div className="quoteContainer">
          <div className="quoteText">
            "Throwing Soup at Art Shifted People's Views of Climate Protests-But
            Maybe Not In The Right Way"{" "}
          </div>{" "}
        </div>{" "}
        <div className="quoteContainer">
          <div
            className="quoteHeader"
            style={{ paddingBottom: "50px", fontWeight: "1000" }}
          >
            TIME{" "}
          </div>
        </div>{" "}
      </div>
      <div className="quoteContainer">
        {" "}
        <div className="quoteContainer">
          <div className="quoteText">
            "Climate protesters campaign by throwing food at art, but does that
            work?"
          </div>{" "}
        </div>{" "}
        <div className="quoteContainer">
          <div className="quoteHeader">NBC News </div>
        </div>{" "}
      </div>
      <div className="quoteContainer" style={{ paddingTop: "50px" }}>
        {" "}
        <div className="quoteContainer">
          <div className="quoteText">
            "Throwing Soup at Art Shifted People's Views of Climate Protests-But
            Maybe Not Just In The Right Way"{" "}
          </div>{" "}
        </div>{" "}
        <div className="quoteContainer">
          <div
            className="quoteHeader"
            style={{ paddingBottom: "50px", fontWeight: "1000" }}
          >
            Washington Post
          </div>
        </div>{" "}
      </div>
    </div>
  );
}
